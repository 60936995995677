const defaultState = {
  data_prep: [{
    first_name: null,
    surname: null,
    role: null,
    company: null,
    email: null,
    work_email: null,
    password: null,
    campaign_referral_code: null,
    link_history: null,
    allow_marketing: false,
    step: 0,
    user_details: null,
    error: null
  }]
}

export const registrationReducer = (state=defaultState, action) => {
  switch (action.type) {
    case 'UPDATE_PLATFORM_REGISTRATION':
      return {
        ...state,
        data_prep: [...state.data_prep, updateField(state.data_prep[state.data_prep.length - 1], action.field, action.value)]
      }
    default:
      return state
  }
}

function updateField(state, field, value) {
  const copy_state = JSON.parse(JSON.stringify(state))
  copy_state[field] = value;
  return copy_state;
}