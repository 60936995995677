const defaultState = {
  menuOpen: false,
  bodyOverflowHidden: false
}

export const mainReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'CLOSE_MENU':
      return {
        ...state,
        menuOpen: false,
      }
    case 'TOGGLE_MENU':
      return {
        ...state,
        menuOpen: !state.menuOpen,
      }
    case 'SET_BODY_OVERFLOW':
      return {
        ...state,
        bodyOverflowHidden: action.data,
      }
    default:
      return state
  }
}
