const moment = require("moment");

export const findLastIndex = (array, searchKey, searchValue) => {
  var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
  var count = array.length - 1
  var finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}

const getUTCTimestamp = (date=null) => {
  return date ? moment.utc(date) : moment.utc();
}

const createDataPrepLinkHistoryObject = (type, url) => {
 return {
   timestamp: getUTCTimestamp(),
   type,
   url
 }
}

export const updateDataPrepLinkHistory = (siteUrl, currentLocation, prevLocation, referrer ) => {
  const linkHistoryStorageName = 'data_prep_link_history';
  const routesLimit = 8;

  let linkHistory = localStorage.getItem(linkHistoryStorageName);
  const type = prevLocation ? 'INT' : 'EXT';
  const currentLocationFormated = createDataPrepLinkHistoryObject(type,  currentLocation);

  if (!linkHistory) {
    const ref_url = referrer.includes(siteUrl) || referrer === '' ? null : referrer;
    const referrerFormatted = createDataPrepLinkHistoryObject('EXT', ref_url);
    linkHistory = JSON.stringify([referrerFormatted, currentLocationFormated]);
  }
  else {
    linkHistory = JSON.parse(linkHistory);
    const linkHistoryReferer = linkHistory.shift();
    const linkHistoryUTM = linkHistory.shift();
    linkHistory.push(currentLocationFormated);

    const latestLinkHistoryRoutes = linkHistory.slice(Math.max(linkHistory.length - routesLimit, 0))

    linkHistory = [linkHistoryReferer, linkHistoryUTM, ...latestLinkHistoryRoutes];
    linkHistory = JSON.stringify(linkHistory);
  }

  localStorage.setItem(linkHistoryStorageName, linkHistory);
}

export const provideAuthorAssets = (name) => {
  name = name.toLowerCase();

  if (name.includes('andy gray')) {
    return {
      name:'Andy Gray',
      role: 'Co-Founder, CEO & CTO',
      avatar: 'avatar-andy-gray/avatar-andy-gray.jpg',
      twitter_url: 'https://twitter.com/Kortical_',
      linkedin_url: 'https://www.linkedin.com/in/andy-gray-67b4492/'
    }
  }
  else if (name.includes('barbara johnson')) {
    return {
      name:'Barbara Johnson',
      role: 'COO',
      avatar: 'avatar-barbara-johnson/avatar-barbara-johnson.jpg',
      twitter_url: 'https://twitter.com/Kortical_',
      linkedin_url: 'https://www.linkedin.com/in/barbara-johnson01/'
    }
  }
  else if (name.includes('alex allan')) {
    return {
      name:'Dr. Alex Allan PhD',
      role: 'Co-Founder & Chief Data Scientist',
      avatar: 'avatar-alex-allan/avatar-alex-allan.jpg',
      twitter_url: 'https://twitter.com/Kortical_',
      linkedin_url: 'https://www.linkedin.com/in/alexallanphd/'
    }
  }
  else if (name.includes('jaclyn de jesus')) {
    return {
      name:'Jaclyn De Jesus',
      role: 'Director',
      avatar: 'avatar-jaclyn-de-jesus/avatar-jaclyn-de-jesus.jpg',
      twitter_url: 'https://twitter.com/Kortical_',
      linkedin_url: 'https://www.linkedin.com/in/jaclyn-de-jesus-3bb17920/'
    }
  }
  else {
    throw 'Authors name provided in provideAuthorAssets does not exist'
  }
}