import ReactGA from 'react-ga'

export const Event = (category, action, label, value) => {
  const windowGlobal = typeof window !== 'undefined' && window.localStorage

  if (windowGlobal) {
    const window_url = new URL(window.location.href);

    ReactGA.event({
      category: category,
      action: action,
      label: label ? label : window_url.pathname,
      value: value !== null ? value : null
    });
  }
}

export const sendClickedEvent = (e) => {
  if (e.target && e.target.id) {
    e = e.target.id
    if (e === 'free-trial-header') {
      e = 'Nav__request-demo'
    }
  }

  if (typeof e === 'string') {
    let id = e
    id = id.split('__')

    if (id[1]) {
      id[1] = id[1].toLowerCase().replace(/\s/g,'-')
    }

    id = id.join('__')
    Event('Clicked', id)
  }

}