const defaultState = {
  sectionTracking: []
}

export const analyticsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'ADD_OR_UPDATE_SECTION_TRACKING':
      return {
        ...state,
        sectionTracking: [...state.sectionTracking, updateSectionTime(state.sectionTracking[state.sectionTracking.length-1] || [], action.data)]
      }
    case 'UPDATE_TOTAL_TIMES_SECTION_TRACKING':
      return {
        ...state,
        sectionTracking: [...state.sectionTracking, updateAllSectionTotalTimes(state.sectionTracking[state.sectionTracking.length-1] || [])]
      }
    default:
      return state
  }
}

function updateSectionTime(array, actionData) {
  if (array.length === 0) {
    array.push(actionData)
  }
  if (!array.some(section => section.id === actionData.id)) {
    array.push(actionData)
  }
  return array.map((section) => {
    if (section.id !== actionData.id) {
      return section
    }
    return {
      ...section,
      ...actionData
    }
  })
}

function updateAllSectionTotalTimes(array) {
  if (array.length === 0) {
    return array
  }

  array.map( section => {
    if (section.hasOwnProperty('total_time')) {
      section['total_time'] = section.total_time + section.time
    } else {
      section['total_time'] = section.time
    }
    section['time'] = 0
    return section
  })

  return array
}
