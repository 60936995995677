import ReactGA from 'react-ga';
import wrapWithProvider from './src/store/provider';
import { Event } from './src/helpers/analytics/event-tracking';
import { updateDataPrepLinkHistory } from './src/helpers/functions';
import * as FullStory from '@fullstory/browser';

export const wrapRootElement = wrapWithProvider;

if (typeof window !== 'undefined' && process.env.GOOGLE_ANALYTICS_ID) {
  // fullstory fires on % of visits and ignores internal users
  if (localStorage.getItem('dtm') !== 'true') {
    if (localStorage.getItem('fs_should_track') === null) {
      const chance_percent = 0.3;
      const should_track = Math.random() >= (1-chance_percent);
      localStorage.setItem('fs_should_track', should_track);
    }

    if (localStorage.getItem('fs_should_track') === 'true') {
      FullStory.init({ orgId: 'QKZ31' });
    }
  }

  // adds listener to GDPR cookie consent banner that fire's pushed callback once interacted with
  // anoymizes analytics data if user declines cookies and ignores internal users
  let tries = 0;
  let hsq = undefined;
  let interval = setInterval(function () {
    hsq = window._hsq;
    if (hsq) {
      hsq.push([
        'addPrivacyConsentListener',
        async consent => {
          const has_internal_dont_track_me = localStorage.getItem('dtm') === 'true'
          const has_fullstory_tracker = localStorage.getItem('fs_should_track') === 'true'

          if (has_internal_dont_track_me) {
            window[`ga-disable-${process.env.GOOGLE_ANALYTICS_ID}`] = true;
            return;
          }

          if (consent.allowed && has_fullstory_tracker) {
            FullStory.consent(true)
          }
          else if (!consent.allowed) {
            await Event('Clicked', '#hs-eu-decline-button');
            function gaOptout(){document.cookie=disableStr+'=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/',window[disableStr]=!0}var gaProperty=`${process.env.GOOGLE_ANALYTICS_ID}`,disableStr='ga-disable-'+gaProperty;document.cookie.indexOf(disableStr+'=true')>-1&&(window[disableStr]=!0);
            ga('set', 'anonymizeIp', 1);

            if (has_fullstory_tracker) {
              FullStory.anonymize();
            }
          }
        }
      ])
    }
    if (++tries === 10 || hsq) {
     window.clearInterval(interval);
    }
  }, 300);
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const page = location.pathname + location.search + location.hash;
  updateDataPrepLinkHistory(process.env.SITE_URL, location.href, prevLocation, document.referrer)

  ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
    titleCase: false
  });
  ReactGA.set({ page });
  ReactGA.pageview(page);
}
