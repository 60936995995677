import { createStore } from 'redux'
import { rootReducer } from './reducers'

const hasWindow = typeof window !== 'undefined'

const reduxDevTools = hasWindow
  ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
  : f => f


const store = createStore(
  rootReducer,
  reduxDevTools,
)

export default store
